import './App.css';
import { PalHeader, PalIcon } from '@pallet/react';

function App() {
  return (
    <div className="App">
      <PalHeader>
        <section slot="header-title">
          <h1>item data management</h1>
          VENDOR PORTAL
        </section>
      </PalHeader>
      <div className="mainText">
        <PalIcon color="#F96302" size="5rem" name="warning-outlined" />
        <h1>
          Our site is down for scheduled maintenance
        </h1>
        <p className="subtext">
          We apologize for the inconvenience.
        </p>
      </div>
    </div>
  );
}

export default App;
